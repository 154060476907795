(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/best-bet-rows/assets/javascripts/best-bet-rows-context.js') >= 0) return;  svs.modules.push('/components/pool-betting/best-bet-rows/assets/javascripts/best-bet-rows-context.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  PARAM_IGNORE_MODIFIED_RESULT
} = svs.components.sport.tipsenShared;
const {
  BestBetRows
} = svs.components.poolBetting.bestBetRows;
const {
  clearModifiedResult,
  setMaskedOutcomes,
  maskEventOutcome,
  unmaskEventOutcome
} = svs.components.tipsen.store.reducers.resultModifier.actions;
const {
  selectResultModifierById
} = svs.components.tipsen.store.reducers.resultModifier.selectors;
const {
  useDrawResultOutcomes
} = svs.components.poolBetting.bestBetRows.hooks;
const {
  composeCacheKey,
  composeModifiedResultOutcomes,
  getCorrectEventCount
} = svs.components.poolBetting.bestBetRows.utils;
const {
  getLogger
} = svs.core.log;
const logger = getLogger('pool-betting:best-bet-rows');
const BestBetRowsContext = React.createContext();

const useBestBetRows = () => {
  const context = useContext(BestBetRowsContext);
  if (context === undefined) {
    throw new Error('useBestBetRows must be used within a BestBetRowsContextProvider');
  }
  return context;
};
setGlobal('svs.components.poolBetting.bestBetRows.hooks.useBestBetRows', useBestBetRows);
const defaultReturn = Object.freeze({
  bestBetRowsAlternative: Object.freeze([]),
  bestBetRows: Object.freeze([]),
  correctEventCount: 0,
  winDivisions: Object.freeze([]),
  winDivisionsRowCount: Object.freeze([]),
  winDivisionsAlternative: Object.freeze([]),
  winDivisionsRowCountAlternative: Object.freeze([]),
  resultOutcomes: Object.freeze([]),
  productId: 0,
  numberOfRows: 0
});
const BestBetRowsContextProvider = _ref => {
  let {
    drawNumber,
    ignoreModifiedResult = false,
    isExamined,
    productId,
    boardData,
    systemType,
    systemNum,
    wagerId,
    rowCountCapLimit = 60,
    children
  } = _ref;
  const dispatch = useDispatch();
  const [drawResultOutcomes, winDivisions] = useDrawResultOutcomes({
    wagerId
  });
  const eventCount = useMemo(() => drawResultOutcomes === null || drawResultOutcomes === void 0 ? void 0 : drawResultOutcomes.length, [drawResultOutcomes]);
  const {
    alternativeOutcomes,
    maskedOutcomes
  } = useSelector(state => selectResultModifierById(state, wagerId));
  const initialMaskedOutcomes = useMemo(() => {
    if (Array.isArray(drawResultOutcomes) && drawResultOutcomes.length > 0) {
      return drawResultOutcomes.map((_, index) => index);
    }
    return [];
  }, [drawResultOutcomes]);
  const maskAllOutcomes = useCallback(() => {
    logger.debug('Mask all outcomes');
    dispatch(setMaskedOutcomes({
      wagerId,
      maskedOutcomes: initialMaskedOutcomes,
      isExamined,
      eventCount,
      productId
    }));
  }, [dispatch, initialMaskedOutcomes, wagerId, isExamined, eventCount, productId]);
  const unmaskOutcomeIndex = useCallback(eventIndex => {
    if (typeof eventIndex !== 'number' || eventIndex < 0) {
      return;
    }
    dispatch(unmaskEventOutcome({
      wagerId,
      eventIndex,
      eventCount,
      productId,
      isExamined
    }));
  }, [dispatch, eventCount, isExamined, productId, wagerId]);
  const maskOutcomeIndex = useCallback(eventIndex => {
    if (typeof eventIndex !== 'number' || eventIndex < 0) {
      return;
    }
    dispatch(maskEventOutcome({
      wagerId,
      eventIndex,
      eventCount,
      productId,
      isExamined
    }));
  }, [dispatch, wagerId, eventCount, productId, isExamined]);
  const clearMaskedOutcomes = useCallback(() => {
    logger.debug('Clear masked outcomes');
    dispatch(setMaskedOutcomes({
      wagerId,
      maskedOutcomes: [],
      productId,
      eventCount,
      isExamined
    }));
  }, [dispatch, eventCount, isExamined, productId, wagerId]);

  useEffect(() => {
    if (!ignoreModifiedResult && Array.isArray(drawResultOutcomes) && drawResultOutcomes.length > 0 && !Array.isArray(maskedOutcomes) && !isExamined) {
      maskAllOutcomes();
    }
  }, [drawResultOutcomes, ignoreModifiedResult, isExamined, maskAllOutcomes, maskedOutcomes]);

  const maskedOutcomeState = useMemo(() => {
    const isInitialized = isExamined || isExamined === false && Array.isArray(maskedOutcomes);
    const hasMaskedOutcomes = isInitialized && (maskedOutcomes === null || maskedOutcomes === void 0 ? void 0 : maskedOutcomes.length) > 0;
    const hasUnmaskedOutcomes = isInitialized && (drawResultOutcomes === null || drawResultOutcomes === void 0 ? void 0 : drawResultOutcomes.length) > 0 && (maskedOutcomes === null || maskedOutcomes === void 0 ? void 0 : maskedOutcomes.length) < drawResultOutcomes.length;
    return {
      isInitialized,
      hasMaskedOutcomes,
      hasUnmaskedOutcomes
    };
  }, [isExamined, maskedOutcomes, drawResultOutcomes]);
  const {
    isInitialized,
    hasMaskedOutcomes,
    hasUnmaskedOutcomes
  } = maskedOutcomeState;
  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    if (params.has(PARAM_IGNORE_MODIFIED_RESULT)) {
      logger.debug('Ignore modified result');
      dispatch(clearModifiedResult({
        wagerId
      }));
    }
  }, [dispatch, wagerId]);
  const bestBetRows = useMemo(() => {
    if (!boardData || !drawNumber || isExamined === undefined) {
      return false;
    }
    return new BestBetRows(boardData, {
      drawNumber,
      rowCountCapLimit,
      productId,
      systemNum,
      systemType
    });
  }, [boardData, drawNumber, isExamined, rowCountCapLimit, productId, systemNum, systemType]);
  const resultOutcomes = useMemo(() => {
    const hasMaskedOutcomes = Array.isArray(maskedOutcomes) && maskedOutcomes.length > 0;
    if (ignoreModifiedResult || !hasMaskedOutcomes) {
      return drawResultOutcomes;
    }
    return composeModifiedResultOutcomes({
      drawResultOutcomes,
      maskedOutcomes
    });
  }, [drawResultOutcomes, ignoreModifiedResult, maskedOutcomes]);
  const resultOutcomesAlternative = useMemo(() => {
    const hasAlternativeOutcomes = Array.isArray(alternativeOutcomes) && alternativeOutcomes.length > 0;
    if (ignoreModifiedResult || !hasAlternativeOutcomes) {
      return drawResultOutcomes;
    }
    return composeModifiedResultOutcomes({
      drawResultOutcomes,
      alternativeOutcomes
    });
  }, [alternativeOutcomes, drawResultOutcomes, ignoreModifiedResult]);
  const cacheKey = composeCacheKey(resultOutcomes);
  const cacheKeyAlternative = composeCacheKey(resultOutcomesAlternative);
  const previousCacheKeyAlternative = useRef(cacheKeyAlternative);
  const bestBetRowCacheAlternative = useRef({});
  const previousCacheKey = useRef(cacheKey);
  const bestBetRowCache = useRef({});
  useEffect(() => {
    if (cacheKey && !previousCacheKey.current) {
      previousCacheKey.current = cacheKey;
    }
  }, [cacheKey]);
  useEffect(() => {
    if (cacheKeyAlternative && !previousCacheKeyAlternative.current) {
      previousCacheKeyAlternative.current = cacheKeyAlternative;
    }
  }, [cacheKeyAlternative]);
  const getBestBetRows = useCallback(function () {
    var _bestBetRowCacheAlter, _bestBetRowCache$curr, _bestBetRowCacheAlter2, _bestBetRowCache$curr2;
    let isCorrectResultIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    if (!bestBetRows) {
      return defaultReturn;
    }

    let newCacheKeyAlternative;
    const hasAlternativeOutcomes = Array.isArray(alternativeOutcomes) && alternativeOutcomes.length > 0;
    if (hasAlternativeOutcomes) {
      bestBetRows.resultOutcomes = resultOutcomesAlternative;
      newCacheKeyAlternative = composeCacheKey(resultOutcomesAlternative);
      if (!bestBetRowCacheAlternative.current[newCacheKeyAlternative]) {
        let bestBetRowValueAlternative = bestBetRows.calculate(winDivisions);
        if (!isCorrectResultIndex) {
          bestBetRowValueAlternative = {
            winDivisionsRowCountAlternative: bestBetRowValueAlternative.winDivisionsRowCount
          };
        }
        bestBetRowCacheAlternative.current[newCacheKeyAlternative] = bestBetRowValueAlternative;
      }
      if (Object.keys(bestBetRowCacheAlternative.current).length > 1 && previousCacheKeyAlternative.current !== newCacheKeyAlternative) {
        logger.info("previousCacheKeyAlternative: ".concat(previousCacheKeyAlternative.current, ", newCacheKeyAlternative: ").concat(newCacheKeyAlternative));
        delete bestBetRowCacheAlternative.current[previousCacheKeyAlternative.current];
        previousCacheKeyAlternative.current = newCacheKeyAlternative;
      }
    }

    bestBetRows.resultOutcomes = resultOutcomes;
    const newCacheKey = composeCacheKey(resultOutcomes);
    if (!bestBetRowCache.current[newCacheKey]) {
      let bestBetRowValue = bestBetRows.calculate(winDivisions);
      if (!isCorrectResultIndex) {
        bestBetRowValue = {
          winDivisionsRowCount: bestBetRowValue.winDivisionsRowCount
        };
      }
      bestBetRowCache.current[newCacheKey] = bestBetRowValue;
    }
    if (Object.keys(bestBetRowCache.current).length > 1 && previousCacheKey.current !== newCacheKey) {
      logger.info("previousCacheKey: ".concat(previousCacheKey.current, ", newCacheKey: ").concat(newCacheKey));
      delete bestBetRowCache.current[previousCacheKey.current];
      previousCacheKey.current = newCacheKey;
    }
    const correctEventCount = getCorrectEventCount(bestBetRowCache.current[newCacheKey].bestBetRows);
    const bestBetRowsAlternative = hasAlternativeOutcomes ? Object.freeze((_bestBetRowCacheAlter = bestBetRowCacheAlternative.current[newCacheKeyAlternative]) === null || _bestBetRowCacheAlter === void 0 ? void 0 : _bestBetRowCacheAlter.bestBetRows) : ((_bestBetRowCache$curr = bestBetRowCache.current[newCacheKey]) === null || _bestBetRowCache$curr === void 0 ? void 0 : _bestBetRowCache$curr.bestBetRows) || Object.freeze([]);
    const winDivisionsRowCountAlternative = hasAlternativeOutcomes ? Object.freeze((_bestBetRowCacheAlter2 = bestBetRowCacheAlternative.current[newCacheKeyAlternative]) === null || _bestBetRowCacheAlter2 === void 0 ? void 0 : _bestBetRowCacheAlter2.winDivisionsRowCount) : ((_bestBetRowCache$curr2 = bestBetRowCache.current[newCacheKey]) === null || _bestBetRowCache$curr2 === void 0 ? void 0 : _bestBetRowCache$curr2.winDivisionsRowCount) || Object.freeze([]);
    return _objectSpread(_objectSpread({}, bestBetRowCache.current[newCacheKey] || defaultReturn), {}, {
      bestBetRowsAlternative,
      winDivisionsRowCountAlternative,
      correctEventCount,
      numberOfRows: bestBetRows.totalRowCount,
      productId,
      resultOutcomes: isInitialized ? Object.freeze(resultOutcomes) : [],
      winDivisions: Object.freeze(winDivisions)
    });
  }, [alternativeOutcomes, bestBetRows, isInitialized, productId, resultOutcomes, resultOutcomesAlternative, winDivisions]);
  const context = useMemo(() => ({
    clearMaskedOutcomes,
    getBestBetRows,
    hasMaskedOutcomes,
    hasUnmaskedOutcomes,
    isInitialized,
    maskAllOutcomes,
    maskedOutcomes,
    maskOutcomeIndex,
    unmaskOutcomeIndex
  }), [clearMaskedOutcomes, getBestBetRows, hasMaskedOutcomes, hasUnmaskedOutcomes, isInitialized, maskAllOutcomes, maskOutcomeIndex, maskedOutcomes, unmaskOutcomeIndex]);
  return React.createElement(BestBetRowsContext.Provider, {
    value: context
  }, children);
};
setGlobal('svs.components.poolBetting.bestBetRows', {
  BestBetRowsContextProvider,
  BestBetRowsContext
});

 })(window);